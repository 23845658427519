import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const Header = ({ username, handleLogout, activeSection = "Objednávky" }) => {
  return (
    <header className="app-header">
      <div className="header-title">
        <h1>
          Systém evidence objednávek <span className="active-section">({activeSection})</span>
        </h1>
        <p className="header-subtitle">Krátký popis systému pro evidenci a správu objednávek</p>
      </div>
      <div className="user-info">
        <span>
          Přihlášený uživatel:&nbsp;
          <span className="logged-username">{username}</span>
        </span>
        <button onClick={handleLogout} className="logout-button" title="Odhlášení uživatele">
          <FontAwesomeIcon icon={faSignOutAlt} />
        </button>
      </div>
    </header>
  );
};

export default Header;
