import React, { useState } from 'react';
import './Login.css';

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === 'spravce' && password === '123456') {
      handleLogin(username);
      setErrorMessage('');
    } else {
      setErrorMessage('Nesprávné přihlašovací údaje!');
    }
  };

  return (
    <div className="login-container">
      <h2>Přihlášení</h2>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Uživatel:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Heslo:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Přihlásit</button>
      </form>
      <p className="copyright">
        &copy; 2025 Robert Holovký, mob: 777 xxyyzz
      </p>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
};

export default Login;
