// components/Orders.js
import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { 
  FaCheckCircle, 
  FaHourglassHalf, 
  FaTimesCircle, 
  FaBan, 
  FaListAlt,
  FaSearch 
} from "react-icons/fa";
import "./Orders.css";

// Registrace komponent Chart.js a pluginu pro datalabels
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Orders = () => {
  // Vzorová data objednávek
  const initialOrders = [
    { id: 1, name: "Objednávka kavovaru", date: "2025-01-01", price: 4200, status: "Hotovo" },
    { id: 2, name: "Objednávka kancelářských židlí", date: "2025-02-10", price: 8700, status: "Otevřeno" },
    { id: 3, name: "Objednávka rekonstrukce kuchyně", date: "2025-03-05", price: 155000, status: "Zrušeno" },
    { id: 4, name: "Objednávka projektoru do konferenční místnosti", date: "2025-03-12", price: 12500, status: "Hotovo" },
    { id: 5, name: "Objednávka firemních vizitek", date: "2025-01-15", price: 3000, status: "Otevřeno" },
    { id: 6, name: "Objednávka vybavení pro catering", date: "2025-02-25", price: 47000, status: "Hotovo" },
    { id: 7, name: "Objednávka ročních diářů", date: "2025-03-01", price: 6500, status: "Zrušeno" },
    { id: 8, name: "Objednávka videokonferenční kamery", date: "2025-04-03", price: 9800, status: "Zamitnute" },
    { id: 9, name: "Objednávka pracovních notebooků", date: "2025-03-18", price: 24000, status: "Hotovo" },
    { id: 10, name: "Objednávka vývoje mobilní aplikace", date: "2025-04-15", price: 600000, status: "Otevřeno" },
    { id: 11, name: "Objednávka úklidových služeb", date: "2025-03-20", price: 18000, status: "Hotovo" },
    { id: 12, name: "Objednávka rozvaděče na sítě", date: "2025-01-31", price: 29000, status: "Zrušeno" },
    { id: 13, name: "Objednávka projektového managementu", date: "2025-02-28", price: 48000, status: "Otevřeno" },
    { id: 14, name: "Objednávka světelného systému", date: "2025-03-09", price: 70000, status: "Hotovo" },
    { id: 15, name: "Objednávka kancelářských stolů", date: "2025-01-22", price: 22000, status: "Zamitnute" },
    { id: 16, name: "Objednávka audiotechniky", date: "2025-02-12", price: 14500, status: "Hotovo" },
    { id: 17, name: "Objednávka systému CRM", date: "2025-03-04", price: 185000, status: "Otevřeno" },
    { id: 18, name: "Objednávka kancelářských potřeb", date: "2025-03-22", price: 9500, status: "Hotovo" },
    { id: 19, name: "Objednávka vybavení kuchyně", date: "2025-04-02", price: 36000, status: "Zrušeno" },
    { id: 20, name: "Objednávka technického školení", date: "2025-02-05", price: 54000, status: "Otevřeno" },
    { id: 21, name: "Objednávka návrhu webových stránek", date: "2025-01-17", price: 120000, status: "Hotovo" },
    { id: 22, name: "Objednávka firemních triček", date: "2025-03-25", price: 8500, status: "Zrušeno" },
    { id: 23, name: "Objednávka bezpečnostního softwaru", date: "2025-02-14", price: 40000, status: "Hotovo" },
    { id: 24, name: "Objednávka pronájmu auta", date: "2025-04-10", price: 15000, status: "Zamitnute" },
    { id: 25, name: "Objednávka aktualizace serverů", date: "2025-03-30", price: 98000, status: "Hotovo" }
  ];
  

  // Stavy pro filtrování a vyhledávání
  const [orders, setOrders] = useState(initialOrders);
  const [activeFilters, setActiveFilters] = useState([]); // Nový stav pro více aktivních filtrů
  const [searchTerm, setSearchTerm] = useState("");

  // Filtrace objednávek na základě aktivních filtrů a hledání
  const filteredOrders = orders.filter((order) => {
    const fullText = `${order.name} ${order.date} ${order.price} ${order.status}`.toLowerCase();
    const matchesSearch = fullText.includes(searchTerm.toLowerCase());
    const matchesFilters = activeFilters.length === 0 || activeFilters.includes(order.status);
    return matchesFilters && matchesSearch;
  });

  // Přepínání filtrů
  const toggleFilter = (status) => {
    setActiveFilters((prevFilters) =>
      prevFilters.includes(status)
        ? prevFilters.filter((filter) => filter !== status) // Odebrání filtru
        : [...prevFilters, status] // Přidání filtru
    );
  };

  // Reset filtrů
  const resetFilters = () => setActiveFilters([]);

  // Data pro koláčový graf
  const pieDataCount = {
    labels: ["Hotovo", "Otevřeno", "Zrušeno", "Zamitnute"],
    datasets: [
      {
        data: [
          filteredOrders.filter((order) => order.status === "Hotovo").length,
          filteredOrders.filter((order) => order.status === "Otevřeno").length,
          filteredOrders.filter((order) => order.status === "Zrušeno").length,
          filteredOrders.filter((order) => order.status === "Zamitnute").length
        ],
        backgroundColor: ["#4caf50", "#ff9800", "#f44336", "#9e9e9e"],
        hoverBackgroundColor: ["#66bb6a", "#ffb74d", "#e57373", "#bdbdbd"]
      }
    ]
  };

  // Nastavení grafu
  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        color: "#fff",
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
          return ((value / total) * 100).toFixed(1) + "%";
        }
      }
    }
  };



  return (
    <div className="orders">
      <div className="summary-categorization">
        <section className="summary">
          <h2>Sumarizace objednávek</h2>
          <p>Celkový počet objednávek: {orders.length}</p>
          <p>Celková cena: {orders.reduce((sum, order) => sum + order.price, 0)} Kč</p>
        </section>
        <section className="categorization">
  <h2>Členění objednávek</h2>
  <div className="categorization-grid">
    <div className="order-card hotovo">
      <FaCheckCircle className="order-icon" />
      <div>
        <p className="order-title">Hotovo</p>
        <p>
          {filteredOrders.filter((order) => order.status === "Hotovo").length} ks /{" "}
          {filteredOrders
            .filter((order) => order.status === "Hotovo")
            .reduce((sum, order) => sum + order.price, 0)}{" "}
          Kč
        </p>
      </div>
    </div>
    <div className="order-card otevreno">
      <FaHourglassHalf className="order-icon" />
      <div>
        <p className="order-title">Otevřeno</p>
        <p>
          {filteredOrders.filter((order) => order.status === "Otevřeno").length} ks /{" "}
          {filteredOrders
            .filter((order) => order.status === "Otevřeno")
            .reduce((sum, order) => sum + order.price, 0)}{" "}
          Kč
        </p>
      </div>
    </div>
    <div className="order-card zruseno">
      <FaTimesCircle className="order-icon" />
      <div>
        <p className="order-title">Zrušeno</p>
        <p>
          {filteredOrders.filter((order) => order.status === "Zrušeno").length} ks /{" "}
          {filteredOrders
            .filter((order) => order.status === "Zrušeno")
            .reduce((sum, order) => sum + order.price, 0)}{" "}
          Kč
        </p>
      </div>
    </div>
    <div className="order-card zamitnute">
      <FaBan className="order-icon" />
      <div>
        <p className="order-title">Zamitnuté</p>
        <p>
          {filteredOrders.filter((order) => order.status === "Zamitnute").length} ks /{" "}
          {filteredOrders
            .filter((order) => order.status === "Zamitnute")
            .reduce((sum, order) => sum + order.price, 0)}{" "}
          Kč
        </p>
      </div>
    </div>
    <div className="categorization-chart">
      <Pie data={pieDataCount} options={chartOptions} />
    </div>
  </div>
</section>

      </div>

      {/* Filtr objednávek */}
      <section className="filter">
        <h2>Filtr objednávek</h2>
        <div className="filter-controls">
          <button onClick={resetFilters}>
            <FaListAlt className="filter-icon" /> Vše
          </button>
          <button
            className={activeFilters.includes("Hotovo") ? "active" : ""}
            onClick={() => toggleFilter("Hotovo")}
          >
            <FaCheckCircle className="filter-icon" /> Hotovo
          </button>
          <button
            className={activeFilters.includes("Otevřeno") ? "active" : ""}
            onClick={() => toggleFilter("Otevřeno")}
          >
            <FaHourglassHalf className="filter-icon" /> Otevřeno
          </button>
          <button
            className={activeFilters.includes("Zrušeno") ? "active" : ""}
            onClick={() => toggleFilter("Zrušeno")}
          >
            <FaTimesCircle className="filter-icon" /> Zrušeno
          </button>
          <button
            className={activeFilters.includes("Zamitnute") ? "active" : ""}
            onClick={() => toggleFilter("Zamitnute")}
          >
            <FaBan className="filter-icon" /> Zamitnuté
          </button>
          <div className="search-box">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Vyhledat objednávky..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </section>

      {/* Seznam objednávek */}
      <section className="order-list">
        <h2>Seznam objednávek</h2>
        <table>
          <thead>
            <tr>
              <th>Název objednávky</th>
              <th>Datum</th>
              <th>Cena</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.name}</td>
                <td>{new Date(order.date).toLocaleDateString("cs-CZ")}</td>
                <td>{order.price} Kč</td>
                <td>{order.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default Orders;