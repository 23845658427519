// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './components/Login';
import Orders from './components/Orders';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  // Při načtení stránky ověříme stav přihlášení v localStorage
  useEffect(() => {
    const savedLoginStatus = localStorage.getItem('isLoggedIn');
    const savedUsername = localStorage.getItem('username');
    if (savedLoginStatus === 'true' && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
    }
  }, []);

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('username', username);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
  };

  if (!isLoggedIn) {
    return <Login handleLogin={handleLogin} />;
  }

  return (
    <div className="app-layout">
      <Header username={username} handleLogout={handleLogout} activeSection="Objednávky" />

      <div className="content">
        {/* Dynamický obsah – dnes například Orders, později Reports, Exporty apod. */}
        <Orders />
      </div>
      <Footer />
    </div>
  );
};

export default App;
